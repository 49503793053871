import React, { Suspense } from 'react';
import Navbar from '../Components/Navbar';
import { Element } from 'react-scroll';
import { CSSReset, Spinner, Center } from '@chakra-ui/react';

const Header = React.lazy(() => import('../Components/Header.js'));
const Party = React.lazy(() => import('../Components/Party'));
const Footer = React.lazy(() => import('../Components/Footer'));
const Contact = React.lazy(() => import('../Components/Contact'));
const Company = React.lazy(() => import('../Components/Company_menu.js'));
const Carousel = React.lazy(() => import('../Components/Carousel.js'));

const Home = () => {
  return (
    <>
      <CSSReset />
      <Navbar />
      <Suspense fallback={
        <Center height="100vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#702E38"
            size="xl"
          />
        </Center>
      }>
        <Element name="home">
          <Header />
        </Element>
        <Element name="party">
          <Party />
        </Element>
        <Carousel />
        <Element name="company_menu">
          <Company />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
        <Footer />
      </Suspense>
    </>
  );
};

export default Home;

import {
  Box,
  Flex,
  Image,
  Link ,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  useColorMode,
  Spacer,
  Container,
  VStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as Links } from 'react-scroll';

import { HamburgerIcon, CloseIcon , MoonIcon, SunIcon } from '@chakra-ui/icons';
import { FaInstagram, FaTwitter } from 'react-icons/fa';
import MainLogo from '../img/logo.svg';

export default function Nav() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
       <Box  h={['auto','120px']}  >
  <Container maxW='container.lg'>
  <Flex  alignItems={"center"} justifyContent={"space-between"}>
     {/* phone menu btn */}
        <Stack  >
            <IconButton
            size={'md'}
            bg={'none'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
            </Stack>
             {/* mon btn here <large> */}
           <Box display={['auto','none']}>
                <Button onClick={toggleColorMode} bg={'none'} _hover={'none'}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
               </Button>
                </Box>
                {/* main nav */}
          <Box>
          <HStack
              as={"nav"}
              spacing={2}
              display={{ base: "none", md: "flex" }}
              color={'000'}
              fontSize="xl"
            >
            
           {/* navlinks and icons */}
           
           <VStack
            align='stretch'
            p='10px'
            m='10px'
         
          >
               {/* phone
               and icons  */}
              
              <HStack spacing={4}  p='5px'>
                {/* mon box  */}
                <Box _hover={{ color: '#F96035'}}>
                <Button onClick={toggleColorMode}   variant='outline'>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
               </Button>
                </Box>
                {/*   <FaWhatsapp />  <FaYoutube /><FaInstagram /> */}
                <Link href="https://x.com/alnufud?lang=en" isExternal _hover={{ color: '#F96035'}}>
                <Button
                leftIcon={<FaTwitter />} 
                variant='outline'
                />
                </Link>

                <Link href="https://www.instagram.com/alnufud/?hl=en" isExternal _hover={{ color: '#F96035'}}>
                <Button
                leftIcon={<FaInstagram />} 
                variant='outline'
                />
                </Link>
               
                {/* phone box */}
         
                <Link href="tel:+966554080101" isExternal _hover={{ color: '#F96035'}}>
                <Text>0554080101</Text> 
                </Link>
      
                   <Link href="tel:920006962" _hover={{ color: '#F96035'}}>
                   <Text> 920006962  </Text>    
                   </Link>
    
         
          {/* Icon box */}
           </HStack>

            {/* navlinks  */}
           <HStack spacing={3} p='5px'>
           <Box cursor="pointer" _hover={{ color: '#F96035'}}>
          <Links to="home" spy={true} smooth={true} duration={500} >
         عن نفود
          </Links>
           </Box>
           {/* <Box cursor="pointer">
        <Links to="menu" spy={true} smooth={true} duration={500} _hover={{ color: '#F96035'}} >
           قائمة الطعام
          </Links>
        </Box> */}
        <Box cursor="pointer" _hover={{ color: '#F96035'}}>
           <Links to="party" spy={true} smooth={true} duration={500}>
              الحفلات والولائم
          </Links>
           </Box>
           {/* <Box cursor="pointer" _hover={{ color: '#F96035'}}>
                              <Links to="#" spy={true} smooth={true} duration={500} >
                             قائمة طعام رمضان  (قريبًا)
                              </Links>
                              </Box> */}
        <Box cursor="pointer" _hover={{ color: '#F96035'}}>
         <Links to="company_menu" spy={true} smooth={true} duration={500}>
         عروض الشركات
          </Links>
         </Box>
        <Box cursor="pointer" _hover={{ color: '#F96035'}}>
         <Links to="contact" spy={true} smooth={true} duration={500}>
          تواصل معنا
          </Links>
         </Box>
         {/* <Box cursor="pointer" _hover={{ color: '#F96035'}}>
         <Links to="contact" spy={true} smooth={true} duration={500} >
          حمل التطبيق
          </Links>
         </Box> */}
         
           </HStack>
          </VStack>
            </HStack>
          </Box>
          <Spacer />

        {  /*  src={MainLogo} */}
          <Flex>
               <Box p='5px'>
                <Image
                //   width={['35vw', '15vw']}
                  src={MainLogo}
                  alt="LOGO"
                />
              </Box>
          </Flex>
        </Flex>
        

        {isOpen ? ( 
            <Box pb={4} display={{ md: 'none' }}>
               
              <Stack as={'nav'} spacing={4}>
                {  <Stack>
                  <Box cursor="pointer">

                              <Links    to="home" spy={true} smooth={true} duration={500} >
                                عن نفود
                              </Links>
                              </Box>
                          
                              <Box cursor="pointer">
                              <Links to="party" spy={true} smooth={true} duration={500} >
                              الحفلات والولائم
                              </Links>
                              </Box>
                              {/* <Box cursor="pointer" >
                              <Links to="#" spy={true} smooth={true} duration={500} >
                             قائمة طعام رمضان  (قريبًا)
                              </Links>
                              </Box> */}
                              <Box cursor="pointer" _hover={{ color: '#F96035'}}>
                              <Links to="company_menu" spy={true} smooth={true} duration={500}>
                              عروض الشركات
                                </Links>
                              </Box>
                              <Box cursor="pointer">
                              <Links to="contact" spy={true} smooth={true} duration={500} >
                              تواصل معنا
                              </Links>
                 </Box>

                 {/* number + icons */}
                 <Box>
                    {/* phone box */}
                    <HStack spacing={3}>
                    <Link href="tel:+966554080101" isExternal >
                <Text>0554080101</Text> 
                </Link>
      
                   <Link href="tel:920006962" >
                   <Text> 920006962  </Text>    
                   </Link>
                    </HStack>
             {/* icons 
                 <faAppStore />
              <faGooglePlay />
             */}
             <HStack spacing={3} mt='3px'>
                  <Box  p='8px'>
                   <Link href="https://x.com/alnufud?lang=en" isExternal><FaTwitter /></Link>
                 </Box>
                <Box p='8px'>
                <Link href="https://www.instagram.com/alnufud/?hl=en" isExternal><FaInstagram /></Link>
                </Box>
            </HStack>
             </Box>
                </Stack>
}
              </Stack>
             
            </Box>
          ) : null}
  </Container>
 
  </Box>
    </>
  );
}